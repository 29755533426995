// Pacakges
import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
// React JSX Components
import MenuArea from '../Common/MenuArea';
import MenuFilters from '../Common/MenuFilters';
import Header from '../../../Common/HeaderFooter/Header';
import DeliveryDetailsSection from '../Common/DeliveryDetails';
import DeliveryDetailsForMobiles from '../Common/DeliveryDetailsForMobiles';
import OrderPopup, { OrderPopupRef } from '../../../Common/Popups/OrderPopup';
import DeliveryOrdersSidebar from '../../../Common/Popups/DeliveryOrdersSidebar';
import GenericMeetingPopUp, { GenericMeetingPopUpRef } from '../../../Common/Popups/GenericMeetingPopUp';
import { MenuFiltersLoading, MenuFoodItemsLoading } from '../../../Common/Loadings/MenuPage/MenuDetailLoadings';
// Redux Actions
import { getCompanyForOrderPurpose } from '../../../Actions/companyActions';
import { getSingleSchedule } from '../../../Actions/scheduleDeliveryActions';
import { getSingleDeliveryMenu, getSchedule } from '../../../Actions/menuActions';
// Utilities
import isEmpty from '../../../Utilities/is_empty';
import { getloggedInUser } from '../../../Utilities/getloggedInUser';
import { getPricesToShown } from '../../../Utilities/getPricesToShown';
import { dashboardTranslations } from '../../../Translations/dashboardTranslations';
import { getResponsiveNoOfSlides } from '../../../Utilities/getResponsiveNoOfSildes';
// Types
import { Delivery } from '../../../Interfaces/PayloadsAndResponses/Schedules';
import { BuffetMenuRstaurantSection } from '../../../Interfaces/PayloadsAndResponses/Menu';
import { GetMenuFilters, Menu, OrderDetails, ReduxRootState } from '../../../Interfaces/ReduxInterface/ReduxRootState';
// CSS
import '../CSS/custom.css';
import 'pure-react-carousel/dist/react-carousel.es.css';
// import EmailVerification from '../../Invites/EmailVerificationInvite/EmailVerification';

type Params = { date: string, token: string };
type SharedMeetingMenuDetailProps = {
  menu: Menu;
  menu_filters_redux: null | GetMenuFilters;
  order: OrderDetails;
  i18n: WithTranslation | null;
  share_meeting: Delivery | undefined;
  getCompanyForOrderPurpose: (token: string) => void;
  getSchedule: (token: string) => void;
  getSingleSchedule: (slug: undefined, setLoading: boolean, token: string) => void;
  getSingleDeliveryMenu: (scheduleSlug: undefined, menu_type?: string, restaurantIDs?: number[], dietaryIDs?: number[], ingredientIDs?: number[], token?: string, filterPurpose?: boolean) => void
} & RouteComponentProps<Params>;

type SharedMeetingMenuDetailState = {
  showDeliveryOrdersSidebar: boolean;
  screenWidth: number;
  isOpenSidebar: boolean;
}

class SharedMeetingMenuDetail extends React.Component<SharedMeetingMenuDetailProps, SharedMeetingMenuDetailState> {

  orderPopup = React.createRef<OrderPopupRef>().current;
  genericMeeting = React.createRef<GenericMeetingPopUpRef>().current;

  initialPrice = 0;
  state: SharedMeetingMenuDetailState = {
    isOpenSidebar: false,
    showDeliveryOrdersSidebar: false, 
    screenWidth: window.document.body.clientWidth,
  }

  updateScreenWidth = () => {
    this.setState({ screenWidth: window.document.body.clientWidth })
  }

  componentDidMount() {
    dashboardTranslations();
    const currentURL = window.location.href;

    const pathSegments = currentURL.split('/');
    const lastSegment = pathSegments.pop(); 

    if (lastSegment?.toLowerCase() === 'order-history') {
      this.setState({ showDeliveryOrdersSidebar: true });
    } else {
      this.setState({ showDeliveryOrdersSidebar: false });
    }

    const { token = '', date } = this.props.match.params || {};
    if (localStorage.headers && localStorage.user && localStorage.company) {
      this.props.history.push(`/menu/${date}`);
    } else {
      if (token.length > 4) {
        this.props.getSingleSchedule(undefined, true, token);
        this.props.getSchedule(token);
        console.log('token length ', token.length)
        this.props.getSingleDeliveryMenu(undefined, 'any', [], [], [], token);
        this.props.getCompanyForOrderPurpose(token);
      } else {
        this.handleBreakRedirection();
      }
    }
    window?.addEventListener('resize', this.updateScreenWidth);
  }

  componentWillUnmount() {
    window?.removeEventListener('resize', this.updateScreenWidth);
  }

  handleBreakRedirection = () => {
    this.props.history.push({ pathname: '/signin', state: { to: this.props.history.location } });
  }
  onUpdateOrder() {
    this.props.getSingleSchedule(undefined, true, this.props.match.params.token);
  }
  componentDidUpdate() {
    const { menu: { schedule }, share_meeting } = this.props;
    if (share_meeting?.share_token && !this.genericMeeting?.modalIsOpen && schedule && isEmpty(schedule.share_meeting.first_name)) {
      this.genericMeeting?.openModal(schedule.share_meeting);
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: SharedMeetingMenuDetailProps) {
    const { share_meeting } = this.props;
    if (share_meeting !== nextProps?.share_meeting) {
      let showDeliveryOrdersSidebar = JSON.parse(localStorage.getItem('order_details') || 'false');
      if (showDeliveryOrdersSidebar && nextProps.share_meeting?.total_quantity) {
        // After Showing Order Detail Popup For the First Time Remove it from LocalStorage and From URL
        localStorage.removeItem('order_details');
        nextProps?.history?.replace({
          search: ''
        });
        this.setState({ showDeliveryOrdersSidebar: showDeliveryOrdersSidebar });
      }
    }
  }

  onUpdatePage() {
    this.props.getSingleDeliveryMenu(undefined, 'any', [], [], [], this.props.match.params.token);
  }
  openModalEdit = (id: number) => {
    if (this.orderPopup) {
      this.orderPopup.setState({ model_type: 'edit', companyData: JSON.parse(localStorage.getItem('company') || '{}') })
      this.orderPopup.getInvitedMeetingOrder(id, this.props.match.params.token);
    }
  }

  truncateString = (str: string, maxLen: number, separator: string = ' ') => {
    if (str?.length <= maxLen) return str;
    return str?.substr(0, str?.lastIndexOf(separator, maxLen)) + '...';
  }
  getPricesToShown(foodPrice: number, skip_markup: boolean, per_meal_budget: number) {
    const { loggedInUserCompany: invitedUserCompany } = getloggedInUser();
    return getPricesToShown(foodPrice, skip_markup, invitedUserCompany, per_meal_budget);
  }

  render() {
    const {
      screenWidth,
      isOpenSidebar,
      showDeliveryOrdersSidebar,
    } = this.state;

    const {
      share_meeting: shareMeetingDetails,
      menu_filters_redux,
      menu: {
        menu = null,
        schedule = null,
        filter_purpose_loading,
        loading: menuLoading = false,
        delivery_dynamic_sections_loading,
      } = {},
      match: { params: { token = '' } }
    } = this.props;

    const {
      noOfSlidesForFoodItems,
      noOfSlidesForRestaurants,
    } = getResponsiveNoOfSlides();

    const showCarouselButtons = screenWidth > 768;
    const { loggedInUserCompany: invitedUserCompany } = getloggedInUser();
    const companyTimeZone = invitedUserCompany?.time_zone;
    const isRestaurantsAndDietariesLoading = (menuLoading && isEmpty(menu)) || ((menuLoading || delivery_dynamic_sections_loading) && !filter_purpose_loading);

    let MenuAreaSection;
    if (menuLoading === true || delivery_dynamic_sections_loading || !shareMeetingDetails?.id || isEmpty(invitedUserCompany)) {
      MenuAreaSection = <MenuFoodItemsLoading totalSlides={Math.ceil(noOfSlidesForFoodItems)} visibleSlides={noOfSlidesForFoodItems} />;
    } else if (menu !== null && !isEmpty(shareMeetingDetails) && menuLoading === false) {
      if (shareMeetingDetails?.menu_type !== 'buffet') {
        MenuAreaSection =
          <MenuArea
            fromInvitedMeeting
            parentRef={this}
            menu={this.props.menu}
            currentMeeting={shareMeetingDetails}
            cutOffPassed={(shareMeetingDetails?.sd_activation_at && dayjs(shareMeetingDetails?.sd_activation_at).isBefore(new Date())) ? dayjs(shareMeetingDetails?.sd_cutoff_at).isBefore(new Date()) : dayjs(shareMeetingDetails?.cutoff_at).isBefore(new Date())}
            showCarouselButtons={showCarouselButtons}
            noOfSlidesForFoodItems={noOfSlidesForFoodItems}
          />
      } else {
        MenuAreaSection = menu.restaurants.map((_restaurant, index) => (
          <div key={index}>
            {!isEmpty(_restaurant && _restaurant.sections) ? _restaurant.sections && _restaurant.sections.map((section, sectionIndex) => {
              const buffetMenuSection = section as unknown as BuffetMenuRstaurantSection;
              return <div key={sectionIndex}>
                <h5 > {_restaurant.name} » {buffetMenuSection.section_name} </h5>
                {buffetMenuSection.fooditems.map((_fooditem, foodItemIndex) => (
                  <div className='newContainer' key={foodItemIndex}>
                    <div className='block_'>
                      <div className='col_4' style={{ backgroundImage: `url(${_fooditem.image.medium.url})` }}></div>
                      <div className='col_8'>
                        <h3 className='text-capitalize float-left card-title mb-1'>{buffetMenuSection.section_type}: {_fooditem.name} </h3>
                        <p className='card-text d-block'>{_fooditem.description} {_fooditem.description ? <br /> : ''}
                        </p>
                        <p>{_fooditem.options ? <b>Option: {_fooditem.options}</b> : ''}</p>
                        <div className='options_'>
                          {!_fooditem.dietaries ? '' : _fooditem.dietaries.map((dietarie, index) => (<span key={index}> {dietarie.name} </span>))}
                          {_fooditem.ingredients && _fooditem.ingredients.map((ingredient, index) => (<span key={index} > {ingredient.name} </span>))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }) : ''
            }
          </div>
        ))
      }
    }

    return (
      <React.Fragment>
        <Header invitedMeeting
          toggleMenuDetailSideBar={() => this.setState({ isOpenSidebar: true })}
          showLeftSidebarToggler
        />
        <div id='show-notification'></div>
        <div>
          <div className='container-fluid px-0' style={{ paddingTop: screenWidth <= 992 ? '73px' : 0 }}>
            {screenWidth > 992 ?
              <DeliveryDetailsSection
                isInvitedMeeting
                currentDelivery={shareMeetingDetails || {} as Delivery}
                companyTimeZone={companyTimeZone}
                isMarketPlacePreview={false}
                parentComponentRef={this}
              /> :
              <>
                <DeliveryDetailsForMobiles
                  currentDelivery={shareMeetingDetails || {} as Delivery}
                  companyTimeZone={companyTimeZone}
                  isMarketPlacePreview={false}
                  parentComponentRef={this}
                  isInvitedMeeting
                  isOpenSidebar={isOpenSidebar}
                  onCloseSideBar={() => this.setState({ isOpenSidebar: false })}
                />
              </>}
            {/* Restaurants Logos and Dietries and Ingredients Section */}
            <div className='restaurants-dietries-restrictions'>
              {/* Restaurants Logos Section Sliders */}
              <div className={`container-fluid px-0`}>
                <div className='px-3 px-md-5'>
                  {isRestaurantsAndDietariesLoading ?
                    <MenuFiltersLoading restaurantSlidesCount={Math?.ceil(noOfSlidesForRestaurants)} /> :
                    <>{menu && <MenuFilters
                      fromInvitedMeeting
                      currentDeliveryShareMeeting={shareMeetingDetails || {} as Delivery}
                      parentState={this.state}
                      menuLoading={menuLoading}
                      invitedMeetingToken={token}
                      dietaries={menu?.dietaries}
                      ingredients={menu?.ingredients}
                      menuFilters={menu_filters_redux?.menu_filters}
                      restaurants={menu_filters_redux?.restaurants}
                      showCarouselButtons={showCarouselButtons}
                      noOfSlidesForRestaurants={noOfSlidesForRestaurants}
                    />}
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12 p-0'>
                <div className='p-4 m-auto mt-3 main-menu-area'>
                  {MenuAreaSection}
                </div>
              </div>
            </div>
          </div>
        </div>
        <DeliveryOrdersSidebar
          invitedMeetingToken={token}
          selectedMeeting={shareMeetingDetails}
          isOpenSidebar={showDeliveryOrdersSidebar}
          updatePageOnOrderDelete={() => this.onUpdateOrder()}
          updateOrder={(id: number) => { this.openModalEdit(id) }}
          closeSidebar={() => { this.setState({ showDeliveryOrdersSidebar: false }); document.title = 'Dashboard | Chowmill'; }}
        />
        <OrderPopup
          // company={company}
          // onDashboard={true}
          invitedMeeting={true}
          token={token}
          setState={(s: SharedMeetingMenuDetailState) => this.setState(s)}
          onRef={(orderPopupRef: OrderPopupRef | null) => (this.orderPopup = orderPopupRef)}
          onUpdatePage={this.onUpdatePage.bind(this)}
          onUpdateOrder={this.onUpdateOrder.bind(this)}
          meetingMealBudget={shareMeetingDetails?.per_meal_budget}
          remaining_budget={shareMeetingDetails?.remaining_budget}
        />
        <GenericMeetingPopUp
          meetingSlug={shareMeetingDetails?.slug}
          token={shareMeetingDetails?.share_token}
          alreadySharedMeeting={schedule?.share_meeting}
          ref={(ref: GenericMeetingPopUpRef | null) => (this.genericMeeting = ref)}
        />
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state: ReduxRootState) => ({
  menu: state.menu,
  menu_filters_redux: state?.menu?.menu_filters,
  order: state.order,
  i18n: state.i18n.i18n,
  share_meeting: state.schedules_details.single_schedule?.schedule,
});

export type SharedMeetingMenuDetailRef = React.ElementRef<typeof SharedMeetingMenuDetail>;

export default connect(mapStateToProps, { getSingleDeliveryMenu, getSingleSchedule, getSchedule, getCompanyForOrderPurpose })(SharedMeetingMenuDetail);