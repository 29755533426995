import React, { FC, useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { getTimeZoneByLocation } from '../Actions/companyActions';
import isEmpty from '../Utilities/is_empty';
import Skeleton from 'react-loading-skeleton';
import { AddressChangeHandler } from '../Pages/AccountDetail/Forms/AddLocationForm';
import { NewLocationModalErrors } from '../Pages/AccountDetail/Modals/AddNewLocationModal';
import { NewDepartmentModalErrors } from '../Pages/AccountDetail/Modals/AddNewDepartmentModal';
import { DeliveryAddressPopupErrors } from './Popups/DeliveryAddressPopUp';

type Props = {
  handleAddress: AddressChangeHandler;
  classnames?: string;
  errors?: DeliveryAddressPopupErrors | NewLocationModalErrors | NewDepartmentModalErrors;
  focusDeliveryAddress?: boolean;
};

const GooglePlacesAutocomplete: FC<Props> = ({
  handleAddress,
  classnames,
  errors,
  focusDeliveryAddress
}) => {

  const [addressLine, setAddressLine] = useState<string>('');
  const [loadingPlace, setLoadingPlace] = useState<boolean>(false);

  // useEffect(() => {
  //   if (window.google && window.google.maps) {
  //     console.log('Google API loaded successfully');
  //   } else {
  //     const script = document.createElement('script');
  //     // const googleAPI = () => {
       
  //     // }
  //     script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDS0wbOsjYPi6CaKvbs13USS5CUOc2D91c&libraries=places&callback=googleAPI";
  //     script.async = true;
  //     script.defer = true;
  //     script.onload = () => {
  //       console.log('Google API loaded successfully');
  //     };
  //     script.onerror = () => {
  //       console.error('Error loading Google API');
  //     };
  
  //     document.head.appendChild(script);
  //   }
  // }, []);


  useEffect(() => {
    // Check if Google Maps API is already loaded
    const isGoogleMapsLoaded = window.google && window.google.maps;

    if (isGoogleMapsLoaded) {
      console.log('Google API already loaded');
      return; 
    }

    const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDS0wbOsjYPi6CaKvbs13USS5CUOc2D91c&libraries=places&callback=googleAPI";
    script.async = true;
    script.defer = true;

    script.onload = () => {
      console.log('Google API loaded successfully');
    };

    script.onerror = () => {
      console.error('Error loading Google API');
    };

    document.head.appendChild(script);
  
  }, []);
  


  const handleChange = (newAddressLine: string) => {

    if (!isEmpty(newAddressLine)) {
      setAddressLine(newAddressLine);
    } else if (isEmpty(newAddressLine) && !isEmpty(addressLine)) {
      setAddressLine(newAddressLine);
    }

    if (isEmpty(newAddressLine)) {
      handleAddress({ addressLine: '' });
    };
  };

  const handleSelect = (address: string) => {
    let city: string, state: string, streetNumber: string, zipCode: number, street: string;

    if (!isEmpty(address)) {
      setLoadingPlace(true);
      geocodeByAddress(address)
        .then(async results => {
          results && results[0] && results[0]?.address_components?.map((item) => {
            item?.types?.forEach(type => {
              if (type === 'locality') {
                city = item.long_name;
              }

              if (type === 'administrative_area_level_1') {
                state = item.short_name;
              }

              if (type === 'street_number') {
                streetNumber = item.short_name;
              }

              if (type === 'route') {
                street = item.short_name;
              }

              if (type === 'postal_code') {
                zipCode = +item.short_name;
              }
            })
            return true;
          });

          let latLang = await getLatLng(results[0]);
          let response = await getTimeZoneByLocation(latLang?.lat, latLang?.lng);
          let timezone = response?.timeZoneId;
          handleAddress({
            city,
            state,
            zipCode,
            streetNumber,
            street,
            addressLine: address,
            longitude: latLang.lng,
            latitude: latLang.lat,
            timezone: timezone
          });
          handleChange(address);
          setLoadingPlace(false);
        })
        .then(latLng => console.log('Success', latLng))
        .catch(error => {
          setLoadingPlace(false);
          console.error('Error', error)
        }
        );
    }
  };

  return (
    <>
    {(window.google && window.google.maps) ? <PlacesAutocomplete
      value={addressLine}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          {loadingPlace ? <Skeleton height={38} /> : <input
            {...getInputProps({
              name: 'addressLine',
              placeholder: 'Search delivery address',
              className: `form-control ${classnames || ''} ${errors?.addressLine ? 'is-invalid' : ''
                }`,
              autoFocus: focusDeliveryAddress ? true : false
            })}
          />}
          <div className='autocomplete-dropdown-container'>
            {/* {loading && <div>Loading...</div>} */}
            {suggestions?.map((suggestion, index) => {
              const className = suggestion.active
                ? 'suggestion-item--active'
                : 'suggestion-item';
              // inline style for demonstration purpose
              const style = suggestion.active
                ? {
                  backgroundColor: '#dee2e6',
                  cursor: 'pointer',
                  padding: '7px',
                  width: '97.5%',
                }
                : {
                  backgroundColor: '#ffffff',
                  cursor: 'pointer',
                  padding: '7px',
                  width: '97.5%',
                };
              return (
                <React.Fragment key={index}>
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                      key: index,
                      onClick: handleSelect
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
    :
    <Skeleton height={10} width={200}/>
    }
    </>
  );
};

export default GooglePlacesAutocomplete;